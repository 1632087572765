import $ from 'jquery';
import axios from 'axios';
import { getAllBetsMarket } from '../../../allBets/Betsmarket';

function oddsClick_teenTest(index,team,rate,rateNo,rateTeam){
$('#teamCas_teenTest_'+index).text(team+'_'+rateTeam+'_'+rateNo);
$('#bet_rate_teenTest_'+index).val(rate);
$('#bet_amount_teenTest_'+index).val('0');
$('.odds_cas').css({background: '#72bbef', border: 'none'});
$('#oddsCas_teenTest_'+rateNo).css({background: '#47a7eb', border: '2px groove black'});
$('#betsPlace_teenTest_'+index).removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_teenTest_'+index).removeClass('active').attr('disabled', 'disabled');
for(let i=0; i < 6; i++){
if(i === index){
$('#placeBetCasino_teenTest_'+i).show();
}
else{
$('#placeBetCasino_teenTest_'+i).hide();
}
}
}

function minusAmt_teenTest(index){
let betAmt = $('#bet_amount_teenTest_'+index).val();
$('#bet_amount_teenTest_'+index).val((parseFloat(betAmt) / 2).toFixed());
}

function plusAmt_teenTest(index){
let betAmt = $('#bet_amount_teenTest_'+index).val();
$('#bet_amount_teenTest_'+index).val((parseFloat(betAmt) + parseFloat(betAmt)).toFixed());
}

function changeAmt_teenTest(index,val){
if(parseFloat(val !== '' ? val : 0) <= 0){
$('#betsPlace_teenTest_'+index).removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_teenTest_'+index).removeClass('active').attr('disabled', 'disabled');
$('#bet_amount_teenTest_'+index).val(0);
}else{
$('#betsPlace_teenTest_'+index).addClass('active').removeAttr('disabled'); 
$('#betsPlace2_teenTest_'+index).addClass('active').removeAttr('disabled');    
$('#bet_amount_teenTest_'+index).val(Math.round(val));
}
}

function stakeBet_teenTest(index,amount){
$('#bet_amount_teenTest_'+index).val(amount); 
$('#betsPlace_teenTest_'+index).addClass('active').removeAttr('disabled');
$('#betsPlace2_teenTest_'+index).addClass('active').removeAttr('disabled');
}

function cancelBet_teenTest(index){
$('#placeBetCasino_teenTest_'+index).hide();
$('#bet_rate_teenTest_'+index).val('');
$('#teamCas_teenTest_'+index).text('');
$('.odds_cas').css({background: '#72bbef', border: 'none'});
}

function betSend_teenTest(index){
$('#betMsgWrapCas_teenTest_'+index).css({display: 'grid'});
$('.odds_cas').attr('disabled', 'disabled');
setTimeout(function(){
finalPlaceBet_teenTest(index);
},2000);
}

async function finalPlaceBet_teenTest(index) {
var selBetType = $('#selBetType_teenTest_'+index).text();
var sec = $('#sec_teenTest_'+index).text();
var sport = $('#sport_teenTest_'+index).text();
var eventname = 'casino';
var teamCas_teenTest = $('#teamCas_teenTest_'+index).text();
var bet_rate_teenTest = $('#bet_rate_teenTest_'+index).val();
var bet_amount_teenTest = $('#bet_amount_teenTest_'+index).val();
var eventid = $('#eventid_teenTest').text();
var userCurr = $('#userCurr_teenTest_'+index).text();
var betdelay = 2;

let minBet = 0;
if(userCurr === 'inr'){
minBet = 100;
}
else{
minBet = 1;  
}

if(eventid === '0'){
$('#bet_msgCas_teenTest_'+index).show().html('roundid changed');
setTimeout(function(){
$('#betMsgWrapCas_teenTest_'+index).css({display: 'none'});
$('#bet_msgCas_teenTest_'+index).hide().html('');
$('.odds_cas').removeAttr('disabled');
},1000);
return false;
}
if(bet_amount_teenTest === '' || bet_amount_teenTest < minBet){
$('#bet_msgCas_teenTest_'+index).show().html('minimum betamount ' + minBet);
setTimeout(function(){
$('#betMsgWrapCas_teenTest_'+index).css({display: 'none'});
$('#bet_msgCas_teenTest_'+index).hide().html('');
$('.odds_cas').removeAttr('disabled');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server/casino_saveBet', {
selBetType: selBetType,
sec: sec,
sport: sport,
eventname: eventname,
teamCas: teamCas_teenTest,
bet_rate: bet_rate_teenTest,
bet_amount: bet_amount_teenTest,
eventid: eventid,
betdelay: betdelay,
sid: 0
});
const resData = await sendData.data;
if(resData === 'success'){
setExposure();
getAllBetsMarket(eventid);
pls_teenTest(eventid,sport);
$('#bet_msgCas_teenTest_'+index).show().html('bet placed');
setTimeout(function(){
$('#betMsgWrapCas_teenTest_'+index).css({display: 'none'});
$('#bet_msgCas_teenTest_'+index).hide().html('');
$('.odds_cas').removeAttr('disabled');
cancelBet_teenTest(index);
},1000);
return false;
}
else{
$('#bet_msgCas_teenTest_'+index).show().html(resData);
setTimeout(function(){
$('#betMsgWrapCas_teenTest_'+index).css({display: 'none'});
$('#bet_msgCas_teenTest_'+index).hide().html('');
$('.odds_cas').removeAttr('disabled');
cancelBet_teenTest(index);
},1000);
}
} catch (error) {
console.log('frontend teenTest_send_savebet data : ' + error);
}
}
}

async function setExposure(){
let currBal = 0;
let currExpo = 0;
const getBal = await axios.get('/server/active-customer');
const balRes = await getBal.data;
if(balRes.length > 0){
currBal = balRes[0].total_balance;
}

const getExpo = await axios.get('/server/user-exposure');
const expoRes = await getExpo.data;
if(expoRes.length > 0){
if(expoRes[0].exposure !== null){
currExpo = expoRes[0].exposure;
}
}

$('#mainBal').html(parseFloat((currBal - currExpo).toFixed(2)).toLocaleString());
$('#mainExpo').html(parseFloat((currExpo).toFixed(2)).toLocaleString());
//console.log('curr balance : '+currBal +' exposure : '+ currExpo);

}

async function pls_teenTest(eventid,sport){
try {
const getData = await axios.get('/server/all-bets');
const resData = await getData.data;
const response = resData.filter((e) => e.sport === sport && e.eventid === eventid);
if(response.length > 0){
var reducePls = response.reduce((a, b) => {
const { team, loss } = b;
const item = a.find((o) => o.team === team);
item ? item.loss += loss : a.push({team, loss});
return a;
},[]);

console.log(reducePls);

if(reducePls.length > 0){
reducePls.forEach(element => {
var split1 = element.team.split('_');
$('#pls_teenTest_'+split1[2]).text(element.loss).css({color: '#bd0000'});
});
}

/*
if(t1Prf > 0){
$('#plsCas_t20_1').html(parseFloat(t1Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#plsCas_t20_1').html(parseFloat(t1Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}

if(t2Prf > 0){
$('#plsCas_t20_3').html(parseFloat(t2Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#plsCas_t20_3').html(parseFloat(t2Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
*/

}
} catch (error) {
console.log('frontend teenTest_pls bets api : ' + error);
}
}

export {
oddsClick_teenTest,
cancelBet_teenTest,
minusAmt_teenTest,
plusAmt_teenTest,
changeAmt_teenTest,
stakeBet_teenTest,
betSend_teenTest,
pls_teenTest
};