import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import SideMenu from '../extra/SideMenu';
import Slider from '../extra/Slider';
import Footer from '../extra/Footer';
import { GlobalContext } from '../context/AppContext';
import AcceptAge from '../modals/AcceptAge';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {

const {customer, cicList, soccList, tennList, spoSett} = GlobalContext();
const [lockSport, setLockSport] = useState([]);
const Navigate = useNavigate();

const cricketLive = cicList.length > 0 && cicList.filter((e) => e.inplay === 'true');
const soccerLive = soccList.length > 0 && soccList.filter((e) => e.iplay === true);
const tennisLive = tennList.length > 0 && tennList.filter((e) => e.iplay === true);

const cricket = () => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'cricket');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/cricket');   
}
}
else{
Navigate('/cricket');
}
}

const soccer = () => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'soccer');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/soccer');   
}
}
else{
Navigate('/soccer');
}
}

const tennis = () => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'tennis');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/tennis');   
}
}
else{
Navigate('/tennis');
}
}

const t20 = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/t20');   
}
}
else{
Navigate('/all-casino/t20');
}
}
else{
$('#AcceptAgeModal').show();
}
}

const tOne = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/t20_oneday');   
}
}
else{
Navigate('/all-casino/t20_oneday');
}
}
else{
$('#AcceptAgeModal').show();
}
}

const tTest = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/t20_test');   
}
}
else{
Navigate('/all-casino/t20_test');
} 
}
else{
$('#AcceptAgeModal').show();
} 
}

const dt20 = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/dt20');   
}
}
else{
Navigate('/all-casino/dt20');
}
}
else{
$('#AcceptAgeModal').show();
}   
}

const dt202 = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/dt202');   
}
}
else{
Navigate('/all-casino/dt202');
}
}
else{
$('#AcceptAgeModal').show();
}   
}

const dtOne = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/dt_1day');   
}
}
else{
Navigate('/all-casino/dt_1day');
}
}
else{
$('#AcceptAgeModal').show();
}      
}

const lu7a = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/lucky_7a');   
}
}
else{
Navigate('/all-casino/lucky_7a');
}
}
else{
$('#AcceptAgeModal').show();
}    
}

const lu7b = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/lucky_7b');   
}
}
else{
Navigate('/all-casino/lucky_7b');
}
}
else{
$('#AcceptAgeModal').show();
}    
}

const andb = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/ab');   
}
}
else{
Navigate('/all-casino/ab');
}
}
else{
$('#AcceptAgeModal').show();
}      
}

const headTail = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/head-tails');   
}
}
else{
Navigate('/head-tails');
}
}
else{
$('#AcceptAgeModal').show();
}      
}

const lucky0to9 = () => {
if (customer.length > 0){
$('#upcomingModal').show();
}
else{
$('#AcceptAgeModal').show();
}      
}

useEffect(() => {
if(customer.length > 0){
if(customer[0].creator !== 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.sub_admin || e.username === f.super_master || e.username === f.master || e.username === f.agent || e.username === f.customer)));
}
else if(customer[0].creator === 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.customer)));
}
}
},[customer, spoSett]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-md'><SideMenu /></div>
<div className='bcols-lg bcols_resp'>
<div className='game-body'>
<Slider />
<div className='game-contents'>
<div className='highlightdIV'><img src='/images/cricket.png' alt='highImg' /> <span>Available Sports</span></div>
<div className='rowsGames'>
<div className='twoCol'>
<div className='liveDiv'>
<span>live</span>
<span>{cricketLive.length !== undefined ? cricketLive.length : 0}</span>
</div>
<div className='img-wrap'>
<img src='/images/picCric.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>cricket</span></div>
<div className='colsSm' onClick={() => cricket()}><span>Play Now</span></div>
</div>
</div>
<div className='twoCol'>
<div className='liveDiv'>
<span>live</span>
<span>{soccerLive.length !== undefined ? soccerLive.length : 0}</span>
</div>
<div className='img-wrap'>
<img src='/images/picSoc.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>soccer</span></div>
<div className='colsSm' onClick={() => soccer()}><span>Play Now</span></div>
</div>
</div>
<div className='twoCol'>
<div className='liveDiv'>
<span>live</span>
<span>{tennisLive.length !== undefined ? tennisLive.length : 0}</span>
</div>
<div className='img-wrap'>
<img src='/images/picTen.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>tennis</span></div>
<div className='colsSm' onClick={() => tennis()}><span>Play Now</span></div>
</div>
</div>
</div>

<div className='highlightdIV'><img src='/images/casino.png' alt='highImg' /> <span>Available Casino</span></div>
<div className='rowsFours'>
<div className='colsFourSm'>
<div className='liveDiv'>
<span className='success'>live</span>
</div>
<div className='img-wrap'>
<img src='/images/teen20.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>Teenpatti 20 20</span></div>
<div className='colsSm' onClick={() => t20()}><span>Play Now</span></div>
</div>
</div>
<div className='colsFourSm'>
<div className='liveDiv'>
<span className='success'>live</span>
</div>
<div className='img-wrap'>
<img src='/images/teenOne.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>Teenpatti OneDay</span></div>
<div className='colsSm' onClick={() => tOne()}><span>Play Now</span></div>
</div>
</div>
<div className='colsFourSm'>
<div className='liveDiv'>
<span className='success'>live</span>
</div>
<div className='img-wrap'>
<img src='/images/teenTest.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>Teenpatti Test</span></div>
<div className='colsSm' onClick={() => tTest()}><span>Play Now</span></div>
</div>
</div>
<div className='colsFourSm'>
<div className='liveDiv'>
<span className='success'>live</span>
</div>
<div className='img-wrap'>
<img src='/images/DT20.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>DT 20 20</span></div>
<div className='colsSm' onClick={() => dt20()}><span>Play Now</span></div>
</div>
</div>
<div className='colsFourSm'>
<div className='liveDiv'>
<span className='success'>live</span>
</div>
<div className='img-wrap'>
<img src='/images/DT20.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>DT 20 20 2</span></div>
<div className='colsSm' onClick={() => dt202()}><span>Play Now</span></div>
</div>
</div>
<div className='colsFourSm'>
<div className='liveDiv'>
<span className='success'>live</span>
</div>
<div className='img-wrap'>
<img src='/images/dtone.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>DT OneDay</span></div>
<div className='colsSm' onClick={() => dtOne()}><span>Play Now</span></div>
</div>
</div>
<div className='colsFourSm'>
<div className='liveDiv'>
<span className='success'>live</span>
</div>
<div className='img-wrap'>
<img src='/images/lu7a.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>Lucky7 A</span></div>
<div className='colsSm' onClick={() => lu7a()}><span>Play Now</span></div>
</div>
</div>
<div className='colsFourSm'>
<div className='liveDiv'>
<span className='success'>live</span>
</div>
<div className='img-wrap'>
<img src='/images/lu7b.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>Lucky7 B</span></div>
<div className='colsSm' onClick={() => lu7b()}><span>Play Now</span></div>
</div>
</div>
<div className='colsFourSm'>
<div className='liveDiv'>
<span className='success'>live</span>
</div>
<div className='img-wrap'>
<img src='/images/andBah.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>Andar Bahar</span></div>
<div className='colsSm' onClick={() => andb()}><span>Play Now</span></div>
</div>
</div>
</div>

<div className='highlightdIV'><img src='/images/virtualCasino.png' alt='highImg' /> <span>Available Virtual Casino</span></div>
<div className='rowsFours'>
<div className='colsFourSm'>
<div className='liveDiv'>
<span className='success'>live</span>
</div>
<div className='img-wrap'>
<img src='/images/head.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>Head & Tails</span></div>
<div className='colsSm' onClick={() => headTail()}><span>Play Now</span></div>
</div>
</div>
<div className='colsFourSm'>
<div className='liveDiv'>
<span className='danger'>upcoming</span>
</div>
<div className='img-wrap'>
<img src='/images/luc9.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>Lucky 0 To 9</span></div>
<div className='colsSm' onClick={() => lucky0to9()}><span>Play Now</span></div>
</div>
</div>
</div>

<Footer />
</div>

</div>
</div>
</div>
</div>
</div>

<AcceptAge />
</>
)
}

export default HomePage;
