import axios from 'axios';
import $ from 'jquery';
import { bettingAct, allSid, matchOddsRunner } from './betting';
import { bettingAct2 } from './fancyBetting';
import { bettingAct3 } from './fancyOdds';
import { bettingAct4 } from './lineBetting';

var startData;
var eventID;
let betAct = false;

async function checkUser(eventid){
const getData = await axios.get('/server/active-customer');
const resData = await getData.data;
//console.log(resData);
if(resData.length > 0){
eventID = eventid;
startData = setInterval(fetchData, 1000);
}
else{
console.log('login error');
}
}

async function fetchData(){
//console.log('ok run ready for refresh data' + eventID);
if(bettingAct === true){
betAct = true;
}
else if(bettingAct2 === true){
betAct = true;
}
else if(bettingAct3 === true){
betAct = true;
}
else if(bettingAct4 === true){
betAct = true;
}
else{
betAct = false;   
}

//console.log(betAct +' -- '+ betAct2 +' -- '+ betAct3 +' -- '+ betAct4);
//console.log(betAct);

try {
const getData = await axios.get('/server/cricket-market/'+eventID);
const resData = await getData.data;
//console.log(resData);
if(resData.data === undefined){
$('.oddsDiv').attr('disabled', 'disabled');
clearInterval(startData);
setTimeout(function(){
startData = setInterval(fetchData, 1000);
},1000); 
}
else{
var normalFancy = resData.data.filter((e) => e.mname === 'normal' && e.gtype === 'fancy');
var Fancy1 = resData.data.filter((e) => e.mname === 'fancy1' && e.gtype === 'fancy1');
var OverByOver = resData.data.filter((e) => e.mname === 'over by over' && e.gtype === 'fancy');
var BallByBall = resData.data.filter((e) => e.mname === 'ball by ball' && e.gtype === 'fancy');
var OddEven = resData.data.filter((e) => e.mname === 'oddeven' && e.gtype === 'oddeven');
var Khado = resData.data.filter((e) => e.mname === 'khado' && e.gtype === 'khado');
var meterMarket = resData.data.filter((e) => e.mname === 'meter' && e.gtype === 'meter');
var lineMarket = resData.data.filter((e) => e.gtype === 'fancy2');
//console.log(matchOdds);
//console.log(bookmaker);

var allSec = [];
    
matchOddsRunner.forEach((e) => {
if(!allSec.includes(e.sec)){
allSec.push(e.sec);
}
});

if(allSec.length > 0){
allSec.forEach((ele) => {
//console.log(resData);
resData.data.filter((e) => e.mname.trim() === ele.trim()).forEach((currVal) => {
//console.log(currVal);
if(currVal.gtype.trim() === 'match'){
let minOdds = 0;
let maxOdds = 0;

if(currVal.status.trim() !== 'open'){
$('#blockTbody_'+currVal.mid).css({display: 'flex'});
$('#blockBodyTxt_'+currVal.mid).text(currVal.status);
}
else{
$('#blockTbody_'+currVal.mid).hide();
}

//console.log(allSid);
allSid.filter((bs) => bs.sec === 'match_odds').forEach((bss) => {
if(bss.type === 'series'){
minOdds = bss.min_odds;
maxOdds = bss.max_odds;
}
else{
minOdds = bss.min_odds;
maxOdds = bss.max_odds;   
}
});

//console.log(minOdds +' -- '+ maxOdds);

currVal.section.forEach(element => {
if(element.gstatus.trim() === 'active' || element.gstatus.trim() === 'open'){
var currBack;
var currLay;

element.odds.forEach((ex) => {
if(ex.otype.trim() === 'back' && ex.oname.trim() === 'back3'){
currBack = $('#price_back_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currBack) !== parseFloat(ex.odds)){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#d7e8f4'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}
else{
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).removeAttr('disabled');
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));   
}
}

if(ex.otype.trim() === 'back' && ex.oname.trim() === 'back2'){
currBack = $('#price_back_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currBack) !== parseFloat(ex.odds)){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#b7d5eb'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}
else{
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).removeAttr('disabled');
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));   
}
}

if(ex.otype.trim() === 'back' && ex.oname.trim() === 'back1'){
currBack = $('#price_back_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currBack) !== parseFloat(ex.odds)){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#72bbef'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}
else{
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).removeAttr('disabled');
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));   
}
}

if(ex.otype.trim() === 'lay' && ex.oname.trim() === 'lay1'){
currLay = $('#price_lay_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currLay) !== parseFloat(ex.odds)){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#80ffbf'});
setTimeout(function(){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#faa9ba'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}
else{
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).removeAttr('disabled');
$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}
}

if(ex.otype.trim() === 'lay' && ex.oname.trim() === 'lay2'){
currLay = $('#price_lay_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currLay) !== parseFloat(ex.odds)){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#80ffbf'});
setTimeout(function(){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#efd3d9'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}
else{
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).removeAttr('disabled');
$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}
}

if(ex.otype.trim() === 'lay' && ex.oname.trim() === 'lay3'){
currLay = $('#price_lay_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currLay) !== parseFloat(ex.odds)){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#80ffbf'});
setTimeout(function(){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#f6e6ea'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}
else{
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).removeAttr('disabled');
$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}
}
}); 
}
});

}
else if(currVal.gtype.trim() === 'match1'){
let minOdds = 0;
let maxOdds = 0;

if(currVal.iplay === false){
$('#blockTbody_'+currVal.mid).css({display: 'flex'});
$('#blockBodyTxt_'+currVal.mid).text(currVal.status);
}
else{
$('#blockTbody_'+currVal.mid).hide();
}

allSid.filter((bs) => bs.sec === 'bookmaker').forEach((bss) => {
if(bss.type === 'series'){
minOdds = bss.min_odds;
maxOdds = bss.max_odds;
}
else{
minOdds = bss.min_odds;
maxOdds = bss.max_odds;   
}
});

currVal.section.forEach((bo) => {
if(currVal.iplay !== false && bo.gstatus.trim() !== 'active'){
$('#blockTdSingle_'+bo.mid+bo.sid).css({display: 'flex'});
$('#blockTdSingleTxt_'+bo.mid+bo.sid).text(bo.gstatus);
$('#placeBet_'+bo.mid+bo.sid).hide();
}
else{
$('#blockTdSingle_'+bo.mid+bo.sid).hide();
}

var currBookBack;
var currBookLay;

bo.odds.forEach((od) => {
if(bo.sid === od.sid){
if(od.otype.trim() === 'back' && od.oname.trim() === 'back3'){
currBookBack = $('#price_back_'+bo.mid+od.sid+od.tno).text();

if(parseFloat(currBookBack) !== parseFloat(od.odds) && parseFloat(od.odds) > 0){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).css({background: ''});
},200);
}

if(parseFloat(od.odds) < parseFloat(minOdds) || parseFloat(od.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).attr('disabled', 'disabled');
$('#price_back_'+bo.mid+od.sid+od.tno).text(od.odds > 0 ? od.odds : '');
$('#price_size_'+bo.mid+od.sid+od.tno).text(od.odds > 0 ? numFormat(od.size) : '');
}
else{
$('#oddsDiv_'+bo.mid+od.sid+od.tno).removeAttr('disabled');
$('#price_back_'+bo.mid+od.sid+od.tno).text(od.odds);
$('#price_size_'+bo.mid+od.sid+od.tno).text(numFormat(od.size));
}
}

if(od.otype.trim() === 'back' && od.oname.trim() === 'back2'){

currBookBack = $('#price_back_'+bo.mid+od.sid+od.tno).text();

if(parseFloat(currBookBack) !== parseFloat(od.odds) && parseFloat(od.odds) > 0){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).css({background: ''});
},200);
}

if(parseFloat(od.odds) < parseFloat(minOdds) || parseFloat(od.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).attr('disabled', 'disabled');
$('#price_back_'+bo.mid+od.sid+od.tno).text(od.odds > 0 ? od.odds : '');
$('#price_size_'+bo.mid+od.sid+od.tno).text(od.odds > 0 ? numFormat(od.size) : '');
}
else{
$('#oddsDiv_'+bo.mid+od.sid+od.tno).removeAttr('disabled');
$('#price_back_'+bo.mid+od.sid+od.tno).text(od.odds);
$('#price_size_'+bo.mid+od.sid+od.tno).text(numFormat(od.size));
}
}

if(od.otype.trim() === 'back' && od.oname.trim() === 'back1'){
currBookBack = $('#price_back_'+bo.mid+od.sid+od.tno).text();

if(parseFloat(currBookBack) !== parseFloat(od.odds) && parseFloat(od.odds) > 0){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).css({background: 'rgb(114, 187, 239)'});
},200);
}

if(parseFloat(od.odds) < parseFloat(minOdds) || parseFloat(od.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).attr('disabled', 'disabled');
$('#price_back_'+bo.mid+od.sid+od.tno).text(od.odds > 0 ? od.odds : '');
$('#price_size_'+bo.mid+od.sid+od.tno).text(od.odds > 0 ? numFormat(od.size) : '');
}
else{
$('#oddsDiv_'+bo.mid+od.sid+od.tno).removeAttr('disabled');
$('#price_back_'+bo.mid+od.sid+od.tno).text(od.odds);
$('#price_size_'+bo.mid+od.sid+od.tno).text(numFormat(od.size));
}
}

if(od.otype.trim() === 'lay' && od.oname.trim() === 'lay1'){
currBookLay = $('#price_lay_'+bo.mid+od.sid+od.tno).text();

if(parseFloat(currBookLay) !== parseFloat(od.odds) && parseFloat(od.odds) > 0){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).css({background: '#faa9ba'});
},200);
}

if(parseFloat(od.odds) < parseFloat(minOdds) || parseFloat(od.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).attr('disabled', 'disabled');
$('#price_lay_'+bo.mid+od.sid+od.tno).text(od.odds > 0 ? od.odds : '');
$('#price_laysize_'+bo.mid+od.sid+od.tno).text(od.odds > 0 ? numFormat(od.size) : '');
}
else{
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).removeAttr('disabled');
$('#price_lay_'+bo.mid+od.sid+od.tno).text(od.odds);
$('#price_laysize_'+bo.mid+od.sid+od.tno).text(numFormat(od.size));
}
}

if(od.otype.trim() === 'lay' && od.oname.trim() === 'lay2'){
currBookLay = $('#price_lay_'+od.sid+od.tno).text();

if(parseFloat(currBookLay) !== parseFloat(od.odds) && parseFloat(od.odds) > 0){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).css({background: ''});
},200);
}

if(parseFloat(od.odds) < parseFloat(minOdds) || parseFloat(od.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).attr('disabled', 'disabled');
$('#price_lay_'+bo.mid+od.sid+od.tno).text(od.odds > 0 ? od.odds : '');
$('#price_laysize_'+bo.mid+od.sid+od.tno).text(od.odds > 0 ? numFormat(od.size) : '');
}
else{
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).removeAttr('disabled');
$('#price_lay_'+bo.mid+od.sid+od.tno).text(od.odds);
$('#price_laysize_'+bo.mid+od.sid+od.tno).text(numFormat(od.size));
}
}

if(od.otype.trim() === 'lay' && od.oname.trim() === 'lay3'){
currBookLay = $('#price_lay_'+bo.mid+od.sid+od.tno).text();

if(parseFloat(currBookLay) !== parseFloat(od.odds) && parseFloat(od.odds) > 0){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).css({background: ''});
},200);
}

if(parseFloat(od.odds) < parseFloat(minOdds) || parseFloat(od.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).attr('disabled', 'disabled');
$('#price_lay_'+bo.mid+od.sid+od.tno).text(od.odds > 0 ? od.odds : '');
$('#price_laysize_'+bo.mid+od.sid+od.tno).text(od.odds > 0 ? numFormat(od.size) : '');
}
else{
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).removeAttr('disabled');
$('#price_lay_'+bo.mid+od.sid+od.tno).text(od.odds);
$('#price_laysize_'+bo.mid+od.sid+od.tno).text(numFormat(od.size));
}
}

}
});
});
}
});
});
}

if(normalFancy.length > 0){
normalFancy[0].section.forEach(element => {

if(normalFancy[0].status !== 'open'){
$('#blockTrF_'+element.mid+element.sid).css({display: 'flex'});
}
else{
$('#blockTrF_'+element.mid+element.sid).hide();
}

if(normalFancy[0].status === 'open' && element.gstatus !== ''){
$('#suspendFancy_'+element.mid+element.sid).css({display: 'flex'});
$('#suspendFancyTxt_'+element.mid+element.sid).text(element.gstatus);
$('#placeBet_'+element.mid+element.sid).hide();
$('#pls2_'+element.mid+element.sid).hide();
}
else{
$('#suspendFancy_'+element.mid+element.sid).hide();
$('#suspendFancyTxt_'+element.mid+element.sid).text('');
}

var currBack;
var currLay;
let minOdds = 0;
let maxOdds = 0;

allSid.filter((bs) => bs.sec === 'fancy').forEach((bss) => {
if(bss.type === 'series'){
minOdds = bss.min_odds;
maxOdds = bss.max_odds;
}
else{
minOdds = bss.min_odds;
maxOdds = bss.max_odds;   
}
});

element.odds.forEach((ex) => {

if(ex.otype === 'back' && ex.oname === 'back1'){
currBack = $('#price_back_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currBack) !== parseFloat(ex.odds) && parseFloat(ex.odds) > 0){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#72bbef'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.odds : '');
$('#price_size_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.size : '');
}
else{
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).removeAttr('disabled');  
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+element.mid+ex.sid+ex.tno).text(ex.size);
}
}

if(ex.otype === 'lay' && ex.oname === 'lay1'){
currLay = $('#price_lay_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currLay) !== parseFloat(ex.odds) && parseFloat(ex.odds) > 0){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#80ffbf'});
setTimeout(function(){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#faa9ba'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.odds : '');
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.size : '');
}
else{
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).removeAttr('disabled');
$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(ex.size);
}
}

}); 

});
}

if(Fancy1.length > 0){

Fancy1[0].section.forEach(element => {

if(Fancy1[0].status !== 'open'){
$('#blockTrF_'+element.mid+element.sid).css({display: 'flex'});
}
else{
$('#blockTrF_'+element.mid+element.sid).hide();
}

if(Fancy1[0].status === 'open' && element.gstatus !== ''){
$('#suspendFancy_'+element.mid+element.sid).css({display: 'flex'});
$('#suspendFancyTxt_'+element.mid+element.sid).text(element.gstatus);
$('#placeBet_'+element.mid+element.sid).hide();
$('#pls2_'+element.mid+element.sid).hide();
}
else{
$('#suspendFancy_'+element.mid+element.sid).hide();
}

var currBack;
var currLay;
let minOdds = 0;
let maxOdds = 0;

allSid.filter((bs) => bs.sec === 'fancy').forEach((bss) => {
if(bss.type === 'series'){
minOdds = bss.min_odds;
maxOdds = bss.max_odds;
}
else{
minOdds = bss.min_odds;
maxOdds = bss.max_odds;   
}
});

element.odds.forEach((ex) => {

if(ex.otype === 'back' && ex.oname === 'back1'){
currBack = $('#price_back_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currBack) !== parseFloat(ex.odds) && parseFloat(ex.odds) > 0){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#72bbef'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.odds : '');
$('#price_size_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? numFormat(ex.size) : '');
}
else{
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).removeAttr('disabled');
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}
}

if(ex.otype === 'lay' && ex.oname === 'lay1'){
currLay = $('#price_lay_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currLay) !== parseFloat(ex.odds) && parseFloat(ex.odds) > 0){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#80ffbf'});
setTimeout(function(){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#faa9ba'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.odds : '');
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? numFormat(ex.size) : '');
}
else{
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).removeAttr('disabled');
$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}
}

}); 

});
}

if(OverByOver.length > 0){

OverByOver[0].section.forEach(element => {

if(OverByOver[0].status !== 'open'){
$('#blockTrF_'+element.mid+element.sid).css({display: 'flex'});
}
else{
$('#blockTrF_'+element.mid+element.sid).hide();
}

if(OverByOver[0].status === 'open' && element.gstatus !== ''){
$('#suspendFancy_'+element.mid+element.sid).css({display: 'flex'});
$('#suspendFancyTxt_'+element.mid+element.sid).text(element.gstatus);
$('#placeBet_'+element.mid+element.sid).hide();
$('#pls2_'+element.mid+element.sid).hide();
}
else{
$('#suspendFancy_'+element.mid+element.sid).hide();
}

var currBack;
var currLay;
let minOdds = 0;
let maxOdds = 0;

allSid.filter((bs) => bs.sec === 'fancy').forEach((bss) => {
if(bss.type === 'series'){
minOdds = bss.min_odds;
maxOdds = bss.max_odds;
}
else{
minOdds = bss.min_odds;
maxOdds = bss.max_odds;   
}
});

element.odds.forEach((ex) => {

if(ex.otype === 'back' && ex.oname === 'back1'){
currBack = $('#price_back_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currBack) !== parseFloat(ex.odds) && parseFloat(ex.odds) > 0){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#72bbef'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.odds : '');
$('#price_size_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.size : '');
}
else{
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).removeAttr('disabled');
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+element.mid+ex.sid+ex.tno).text(ex.size);
}
}

if(ex.otype === 'lay' && ex.oname === 'lay1'){
currLay = $('#price_lay_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currLay) !== parseFloat(ex.odds) && parseFloat(ex.odds) > 0){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#80ffbf'});
setTimeout(function(){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#faa9ba'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).attr('disabled', 'disabled');  
$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.odds : '');
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.size : '');
}
else{
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).removeAttr('disabled');  
$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(ex.size);
}
}

}); 

});
}

if(BallByBall.length > 0){

BallByBall[0].section.forEach(element => {

if(BallByBall[0].status !== 'open'){
$('#blockTrF_'+element.mid+element.sid).css({display: 'flex'});
}
else{
$('#blockTrF_'+element.mid+element.sid).hide();
}

if(BallByBall[0].status === 'open' && element.gstatus !== ''){
$('#suspendFancy_'+element.mid+element.sid).css({display: 'flex'});
$('#suspendFancyTxt_'+element.mid+element.sid).text(element.gstatus);
$('#placeBet_'+element.mid+element.sid).hide();
$('#pls2_'+element.mid+element.sid).hide();
}
else{
$('#suspendFancy_'+element.mid+element.sid).hide();
}

var currBack;
var currLay;
let minOdds = 0;
let maxOdds = 0;

allSid.filter((bs) => bs.sec === 'fancy').forEach((bss) => {
if(bss.type === 'series'){
minOdds = bss.min_odds;
maxOdds = bss.max_odds;
}
else{
minOdds = bss.min_odds;
maxOdds = bss.max_odds;   
}
});

element.odds.forEach((ex) => {

if(ex.otype === 'back' && ex.oname === 'back1'){
currBack = $('#price_back_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currBack) !== parseFloat(ex.odds) && parseFloat(ex.odds) > 0){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#72bbef'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.odds : '');
$('#price_size_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.size : '');
}
else{
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).removeAttr('disabled');
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+element.mid+ex.sid+ex.tno).text(ex.size);
}
}

if(ex.otype === 'lay' && ex.oname === 'lay1'){
currLay = $('#price_lay_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currLay) !== parseFloat(ex.odds) && parseFloat(ex.odds) > 0){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#80ffbf'});
setTimeout(function(){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#faa9ba'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.odds : '');
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.size : '');
}
else{
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).removeAttr('disabled');  
$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(ex.size); 
}
}

}); 

});
}

if(OddEven.length > 0){

OddEven[0].section.forEach(element => {

if(OddEven[0].status !== 'open'){
$('#blockTrF_'+element.mid+element.sid).css({display: 'flex'});
}
else{
$('#blockTrF_'+element.mid+element.sid).hide();
}

if(OddEven[0].status === 'open' && element.gstatus !== ''){
$('#suspendFancy_'+element.mid+element.sid).css({display: 'flex'});
$('#suspendFancyTxt_'+element.mid+element.sid).text(element.gstatus);
$('#placeBet_'+element.mid+element.sid).hide();
$('#pls2_'+element.mid+element.sid).hide();
}
else{
$('#suspendFancy_'+element.mid+element.sid).hide();
}

var currBack;
var currLay;
let minOdds = 0;
let maxOdds = 0;

allSid.filter((bs) => bs.sec === 'fancy').forEach((bss) => {
if(bss.type === 'series'){
minOdds = bss.min_odds;
maxOdds = bss.max_odds;
}
else{
minOdds = bss.min_odds;
maxOdds = bss.max_odds;   
}
});

element.odds.forEach((ex) => {

if(ex.otype === 'back' && ex.oname === 'back1'){
currBack = $('#price_back_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currBack) !== parseFloat(ex.odds) && parseFloat(ex.odds) > 0){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#72bbef'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.odds : '');
$('#price_size_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? numFormat(ex.size) : '');
}
else{
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).removeAttr('disabled');
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}
}

if(ex.otype === 'lay' && ex.oname === 'lay1'){
currLay = $('#price_lay_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currLay) !== parseFloat(ex.odds) && parseFloat(ex.odds) > 0){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#80ffbf'});
setTimeout(function(){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#faa9ba'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.odds : '');
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? numFormat(ex.size) : '');
}
else{
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).removeAttr('disabled'); 
$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}
}

}); 

});
}

if(Khado.length > 0){

Khado[0].section.forEach(element => {

if(Khado[0].status !== 'open'){
$('#blockTrF_'+element.mid+element.sid).css({display: 'flex'});
}
else{
$('#blockTrF_'+element.mid+element.sid).hide();
}

if(Khado[0].status === 'open' && element.gstatus !== ''){
$('#suspendFancy_'+element.mid+element.sid).css({display: 'flex'});
$('#suspendFancyTxt_'+element.mid+element.sid).text(element.gstatus);
$('#placeBet_'+element.mid+element.sid).hide();
$('#pls2_'+element.mid+element.sid).hide();
}
else{
$('#suspendFancy_'+element.mid+element.sid).hide();
}

var currBack;
let minOdds = 0;
let maxOdds = 0;

allSid.filter((bs) => bs.sec === 'fancy').forEach((bss) => {
if(bss.type === 'series'){
minOdds = bss.min_odds;
maxOdds = bss.max_odds;
}
else{
minOdds = bss.min_odds;
maxOdds = bss.max_odds;   
}
});

element.odds.forEach((ex) => {

if(ex.otype === 'back' && ex.oname === 'back1'){
currBack = $('#price_back_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currBack) !== parseFloat(ex.odds) && parseFloat(ex.odds) > 0){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#72bbef'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.odds : '');
$('#price_size_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.size : '');
}
else{
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).removeAttr('disabled');
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+element.mid+ex.sid+ex.tno).text(ex.size);
}
}

/*
if(ex.otype === 'lay' && ex.oname === 'lay1'){
currLay = $('#price_lay_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currLay) !== parseFloat(ex.odds)){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#80ffbf'});
setTimeout(function(){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#faa9ba'});
},200);
}

$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(ex.size);
}
*/
}); 
});
}

if(meterMarket.length > 0){

meterMarket[0].section.forEach(element => {

if(meterMarket[0].status !== 'open'){
$('#blockTrF_'+element.mid+element.sid).css({display: 'flex'});
}
else{
$('#blockTrF_'+element.mid+element.sid).hide();
}

if(meterMarket[0].status === 'open' && element.gstatus !== ''){
$('#suspendFancy_'+element.mid+element.sid).css({display: 'flex'});
$('#suspendFancyTxt_'+element.mid+element.sid).text(element.gstatus);
$('#placeBet_'+element.mid+element.sid).hide();
$('#pls2_'+element.mid+element.sid).hide();
}
else{
$('#suspendFancy_'+element.mid+element.sid).hide();
}

var currBack;
var currLay;
let minOdds = 0;
let maxOdds = 0;

allSid.filter((bs) => bs.sec === 'fancy').forEach((bss) => {
if(bss.type === 'series'){
minOdds = bss.min_odds;
maxOdds = bss.max_odds;
}
else{
minOdds = bss.min_odds;
maxOdds = bss.max_odds;   
}
});

element.odds.forEach((ex) => {

if(ex.otype === 'back' && ex.oname === 'back1'){
currBack = $('#price_back_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currBack) !== parseFloat(ex.odds) && parseFloat(ex.odds) > 0){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#72bbef'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.odds : '');
$('#price_size_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.size : '');
}
else{
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).removeAttr('disabled');
$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+element.mid+ex.sid+ex.tno).text(ex.size);
}
}

if(ex.otype === 'lay' && ex.oname === 'lay1'){
currLay = $('#price_lay_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currLay) !== parseFloat(ex.odds) && parseFloat(ex.odds) > 0){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#80ffbf'});
setTimeout(function(){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#faa9ba'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.odds : '');
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.size : '');
}
else{
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).removeAttr('disabled');  
$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(ex.size);
}
}
}); 
});
}

if(lineMarket.length > 0){
lineMarket.forEach((e) => {
if(e.status !== 'open'){
$('#blockTrF_'+e.mid).css({display: 'flex'});
$('#blockTrF_txt_'+e.mid).text(e.status);
}
else{
$('#blockTrF_'+e.mid).hide();
}

e.section.forEach((s) => {
if(e.status === 'open' && s.gstatus !== 'active'){
$('#suspendFancy_'+e.mid+s.sid).css({display: 'flex'});
$('#suspendFancyTxt_'+e.mid+s.sid).text(s.gstatus);
$('#placeBet_'+e.mid).hide();
}
else{
$('#suspendFancy_'+e.mid+s.sid).hide();
}

var currBack;
var currLay;
let minOdds = 0;
let maxOdds = 0;

allSid.filter((bs) => bs.sec === 'fancy').forEach((bss) => {
if(bss.type === 'series'){
minOdds = bss.min_odds;
maxOdds = bss.max_odds;
}
else{
minOdds = bss.min_odds;
maxOdds = bss.max_odds;   
}
});

s.odds.forEach((ex) => {

if(ex.otype === 'back' && ex.oname === 'back1'){
currBack = $('#price_back_'+s.mid+ex.sid+ex.tno).text();

if(parseFloat(currBack) !== parseFloat(ex.odds) && parseFloat(ex.odds) > 0){
$('#oddsDiv_'+s.mid+ex.sid+ex.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+s.mid+ex.sid+ex.tno).css({background: '#72bbef'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDiv_'+s.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_back_'+s.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.odds : '');
$('#price_size_'+s.mid+ex.sid+ex.tno).text(ex.odds > 0 ? numFormat(ex.size) : '');
}
else{
$('#oddsDiv_'+s.mid+ex.sid+ex.tno).removeAttr('disabled');
$('#price_back_'+s.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+s.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}
}

if(ex.otype === 'back' && ex.oname === 'back2'){
currBack = $('#price_back_'+s.mid+ex.sid+ex.tno).text();

if(parseFloat(currBack) !== parseFloat(ex.odds) && parseFloat(ex.odds) > 0){
$('#oddsDiv_'+s.mid+ex.sid+ex.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+s.mid+ex.sid+ex.tno).css({background: '#72bbef'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDiv_'+s.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_back_'+s.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.odds : '');
$('#price_size_'+s.mid+ex.sid+ex.tno).text(ex.odds > 0 ? numFormat(ex.size) : '');
}
else{
$('#oddsDiv_'+s.mid+ex.sid+ex.tno).removeAttr('disabled');
$('#price_back_'+s.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+s.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}
}

if(ex.otype === 'back' && ex.oname === 'back3'){
currBack = $('#price_back_'+s.mid+ex.sid+ex.tno).text();

if(parseFloat(currBack) !== parseFloat(ex.odds) && parseFloat(ex.odds) > 0){
$('#oddsDiv_'+s.mid+ex.sid+ex.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+s.mid+ex.sid+ex.tno).css({background: '#72bbef'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDiv_'+s.mid+ex.sid+ex.tno).attr('disabled', 'disabled'); 
$('#price_back_'+s.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.odds : '');
$('#price_size_'+s.mid+ex.sid+ex.tno).text(ex.odds > 0 ? numFormat(ex.size) : '');
}
else{
$('#oddsDiv_'+s.mid+ex.sid+ex.tno).removeAttr('disabled');   
$('#price_back_'+s.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+s.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}
}

if(ex.otype === 'lay' && ex.oname === 'lay1'){
currLay = $('#price_lay_'+s.mid+ex.sid+ex.tno).text();

if(parseFloat(currLay) !== parseFloat(ex.odds) && parseFloat(ex.odds) > 0){
$('#oddsDivLay_'+s.mid+ex.sid+ex.tno).css({background: '#80ffbf'});
setTimeout(function(){
$('#oddsDivLay_'+s.mid+ex.sid+ex.tno).css({background: '#faa9ba'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDivLay_'+s.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_lay_'+s.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.odds : '');
$('#price_laysize_'+s.mid+ex.sid+ex.tno).text(ex.odds > 0 ? numFormat(ex.size) : '');
}
else{
$('#oddsDivLay_'+s.mid+ex.sid+ex.tno).removeAttr('disabled'); 
$('#price_lay_'+s.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+s.mid+ex.sid+ex.tno).text(numFormat(ex.size));  
}
}

if(ex.otype === 'lay' && ex.oname === 'lay2'){
currLay = $('#price_lay_'+s.mid+ex.sid+ex.tno).text();

if(parseFloat(currLay) !== parseFloat(ex.odds) && parseFloat(ex.odds) > 0){
$('#oddsDivLay_'+s.mid+ex.sid+ex.tno).css({background: '#80ffbf'});
setTimeout(function(){
$('#oddsDivLay_'+s.mid+ex.sid+ex.tno).css({background: '#faa9ba'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDivLay_'+s.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_lay_'+s.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.odds : '');
$('#price_laysize_'+s.mid+ex.sid+ex.tno).text(ex.odds > 0 ? numFormat(ex.size) : '');
}
else{
$('#oddsDivLay_'+s.mid+ex.sid+ex.tno).removeAttr('disabled');
$('#price_lay_'+s.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+s.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}
}

if(ex.otype === 'lay' && ex.oname === 'lay3'){
currLay = $('#price_lay_'+s.mid+ex.sid+ex.tno).text();

if(parseFloat(currLay) !== parseFloat(ex.odds) && parseFloat(ex.odds) > 0){
$('#oddsDivLay_'+s.mid+ex.sid+ex.tno).css({background: '#80ffbf'});
setTimeout(function(){
$('#oddsDivLay_'+s.mid+ex.sid+ex.tno).css({background: '#faa9ba'});
},200);
}

if(parseFloat(ex.odds) < parseFloat(minOdds) || parseFloat(ex.odds) > parseFloat(maxOdds) || betAct === true){
$('#oddsDivLay_'+s.mid+ex.sid+ex.tno).attr('disabled', 'disabled');
$('#price_lay_'+s.mid+ex.sid+ex.tno).text(ex.odds > 0 ? ex.odds : '');
$('#price_laysize_'+s.mid+ex.sid+ex.tno).text(ex.odds > 0 ? numFormat(ex.size) : '');
}
else{
$('#oddsDivLay_'+s.mid+ex.sid+ex.tno).removeAttr('disabled');  
$('#price_lay_'+s.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+s.mid+ex.sid+ex.tno).text(numFormat(ex.size)); 
}
}
}); 
});
});
}
} 
} catch (error) {
clearInterval(startData);
setTimeout(function(){
startData = setInterval(fetchData, 1000);
},1000); 
}
}

function numFormat(number){
if (number < 1000) {
return number;
} else if (number >= 1000 && number < 1_000_000) {
return (number / 1000).toFixed(1).replace(/\.0$/, "") + "K";
} else if (number >= 1_000_000 && number < 1_000_000_000) {
return (number / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
} else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
} else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
return (number / 1_000_000_000_000).toFixed(1).replace(/\.0$/, "") + "T";
}
}

export {
checkUser,
startData,
};